<template>
  <v-card elevation="3" style="width: 100%; min-height: 500px;">

    <v-row no-gutters class="mx-2">

      <!-- Button
      <v-col cols="12">
        <v-row justify="end" no-gutters>
          <v-btn
            disabled
            icon
            class="btn-primary"
            color="primary"
            @click="setShowEditDialog(true)">
            <v-icon small>fas fa-pencil</v-icon>
          </v-btn>
        </v-row>
      </v-col-->

      <!-- Info -->
      <v-col cols="12">

        <v-row no-gutters>

          <!-- Persons -->
          <v-col cols="12">
            <h3>{{ $t('accounts.tabs.contacts.persons.title')}}</h3>
            <v-row v-if="account.contacts.length > 0" no-gutters>
              <v-col
                v-for="person of account.contacts"
                cols="12"
                md="4"
                :key="person.id">
                <Person
                  :person="person.person"
                />
              </v-col>
            </v-row>

            <v-row v-else no-gutters>
              <p>{{ $t('accounts.tabs.contacts.persons.no-data') }}</p>
            </v-row>
          </v-col>

          <!-- Secondary Addresses -->
          <v-col cols="12">
            <h3>{{ $t('accounts.tabs.contacts.addresses.title')}}</h3>
            <v-row no-gutters>

              <v-col cols="12" md="4" v-if="account.main_address">
                <Address
                  :address="account.main_address"
                />
              </v-col>
              <template v-if="account.secondary_addresses.length > 0">

              <v-col v-for="address of account.secondary_addresses" cols="12" md="4" :key="address.id">
                <Address
                  :address="address"
                />
              </v-col>
              </template>
              <p v-else> {{ $t('accounts.tabs.contacts.addresses.no-data') }}</p>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "AccountContactsTab",

  components: {
    Skeleton: () => import("@/components/Common/Cards/Skeleton"),
    Address: () => import("@/components/Common/Cards/Address"),
    Person: () => import("@/components/Common/Cards/Person")
  },
  mixins: [],

  props: {
    account: {type: Object, default: () => null}
  },

  data() {
    return {
      showEditDialog: false,
    }
  },

  methods: {
    getData() {
      this.$emit('refresh');
    },

    setShowEditDialog(val, needRefresh = false) {
      if (needRefresh)
        this.getData();
      this.showEditDialog = val;
    }
  }
}
</script>

<style scoped>

</style>
